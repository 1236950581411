var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "load-status-edit-button font-weight-bold",
                      class: {
                        "yellow darken-2":
                          _vm.buttonColors.plan || _vm.buttonColors.needsAppt,
                        "green darken-2": _vm.buttonColors.available,
                        "indigo lighten-1": _vm.buttonColors.booked,
                        "purple lighten-1": _vm.buttonColors.confirmed,
                        "blue lighten-1":
                          _vm.buttonColors.picked ||
                          _vm.buttonColors.pickOnTrack ||
                          _vm.buttonColors.loading,
                        "green lighten-1":
                          _vm.buttonColors.delivered ||
                          _vm.buttonColors.deliveryOnTrack ||
                          _vm.buttonColors.unloading ||
                          _vm.buttonColors.readyToBill,
                        "red darken-1":
                          _vm.buttonColors.claim ||
                          _vm.buttonColors.deliveryOffTrack ||
                          _vm.buttonColors.pickOffTrack,
                        "blue-grey darken-2": _vm.buttonColors.invoiced,
                        black: _vm.buttonColors.paid,
                      },
                      attrs: {
                        disabled: _vm.isArchived || _vm.disabled,
                        small: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.isArchived ? _vm.loadStatusDisplay : "Archived"
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { height: "300" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { color: "accent" },
              model: {
                value: _vm.loadDisplay,
                callback: function ($$v) {
                  _vm.loadDisplay = $$v
                },
                expression: "loadDisplay",
              },
            },
            _vm._l(_vm.loadStatusItems, function (item, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.saveLoadUpdate(item.id)
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }