var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.isLoadTrackingDialogOpen,
            callback: function ($$v) {
              _vm.isLoadTrackingDialogOpen = $$v
            },
            expression: "isLoadTrackingDialogOpen",
          },
        },
        [
          _c(
            "v-card",
            { key: _vm.loadTrackingDialogId },
            [
              _c(
                "v-card-title",
                { staticStyle: { "background-color": "#1e88e5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "text-h2 white--text" }, [
                          _vm._v("Tracking Update"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.loadingIcon
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "load-tracking-form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("load-status-edit-button", {
                                    attrs: {
                                      disabled: _vm.disableStatusElement,
                                      "load-status":
                                        _vm.shipmentDetailCopy &&
                                        _vm.shipmentDetailCopy
                                          .loadStatusDisplay,
                                      "shipment-id":
                                        _vm.shipmentDetailCopy &&
                                        _vm.shipmentDetailCopy.id,
                                    },
                                    on: { change: _vm.setLoadStatus },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("date-time-picker", {
                                    attrs: {
                                      datetime: _vm.pickupApptAt,
                                      label: "Pickup Appointment",
                                    },
                                    on: {
                                      "update:datetime": function ($event) {
                                        _vm.pickupApptAt = $event
                                      },
                                      input: function ($event) {
                                        return _vm.onDateTimeChange(
                                          $event,
                                          "pickupApptAt"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("date-time-picker", {
                                    attrs: {
                                      datetime: _vm.deliveryApptAt,
                                      label: "Delivery Appointment",
                                    },
                                    on: {
                                      "update:datetime": function ($event) {
                                        _vm.deliveryApptAt = $event
                                      },
                                      input: function ($event) {
                                        return _vm.onDateTimeChange(
                                          $event,
                                          "deliveryApptAt"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("date-time-picker", {
                                    attrs: {
                                      datetime: _vm.arrivedAtShipperAt,
                                      required: _vm.isArrivedAtShipperRequired,
                                      label: "Arrived at Shipper",
                                    },
                                    on: {
                                      "update:datetime": function ($event) {
                                        _vm.arrivedAtShipperAt = $event
                                      },
                                      input: function ($event) {
                                        return _vm.onDateTimeChange(
                                          $event,
                                          "arrivedAtShipperAt"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("date-time-picker", {
                                    attrs: {
                                      datetime: _vm.arrivedAtConsigneeAt,
                                      required:
                                        _vm.isArrivedAtConsigneeRequired,
                                      label: "Arrived at Consignee",
                                    },
                                    on: {
                                      "update:datetime": function ($event) {
                                        _vm.arrivedAtConsigneeAt = $event
                                      },
                                      input: function ($event) {
                                        return _vm.onDateTimeChange(
                                          $event,
                                          "arrivedAtConsigneeAt"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("date-time-picker", {
                                    attrs: {
                                      datetime: _vm.pickedAt,
                                      required: _vm.isPickedAtRequired,
                                      label: "Loaded",
                                    },
                                    on: {
                                      "update:datetime": function ($event) {
                                        _vm.pickedAt = $event
                                      },
                                      input: function ($event) {
                                        return _vm.onDateTimeChange(
                                          $event,
                                          "pickedAt"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.shipmentDetail
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("date-time-picker", {
                                        attrs: {
                                          datetime: _vm.deliveredAt,
                                          label: "Delivered",
                                        },
                                        on: {
                                          "update:datetime": function ($event) {
                                            _vm.deliveredAt = $event
                                          },
                                          input: function ($event) {
                                            return _vm.onDateTimeChange(
                                              $event,
                                              "deliveredAt"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      color: "success",
                                      "hide-details": "",
                                      label: "On Time Pick",
                                    },
                                    model: {
                                      value: _vm.trackingUpdate.onTimePick,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.trackingUpdate,
                                          "onTimePick",
                                          $$v
                                        )
                                      },
                                      expression: "trackingUpdate.onTimePick",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      color: "success",
                                      "hide-details": "",
                                      label: "On Time Delivery",
                                    },
                                    model: {
                                      value: _vm.trackingUpdate.onTimeDelivery,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.trackingUpdate,
                                          "onTimeDelivery",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trackingUpdate.onTimeDelivery",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "City", type: "text" },
                                    model: {
                                      value: _vm.city,
                                      callback: function ($$v) {
                                        _vm.city = $$v
                                      },
                                      expression: "city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.state],
                                      hint: "Short Name (TX for Texas)",
                                      label: "State Province Region",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.state,
                                      callback: function ($$v) {
                                        _vm.state = $$v
                                      },
                                      expression: "state",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center text-h4 pb-0",
                                  attrs: { cols: "12" },
                                },
                                [_vm._v(" EDI Update ")]
                              ),
                              _vm.loadStopData.length > 0
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.loadStopData,
                                          clearable: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          label: "Load Stop",
                                        },
                                        model: {
                                          value: _vm.loadStopId,
                                          callback: function ($$v) {
                                            _vm.loadStopId = $$v
                                          },
                                          expression: "loadStopId",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.ediEventTypeOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "EDI Event Type",
                                    },
                                    on: { change: _vm.loadReasonCode },
                                    model: {
                                      value: _vm.ediEventType,
                                      callback: function ($$v) {
                                        _vm.ediEventType = $$v
                                      },
                                      expression: "ediEventType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.ediReasonCodeData,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "EDI Reason Code",
                                    },
                                    model: {
                                      value: _vm.ediReasonCode,
                                      callback: function ($$v) {
                                        _vm.ediReasonCode = $$v
                                      },
                                      expression: "ediReasonCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "gray", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitLoad()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }