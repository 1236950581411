var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shipmentDetail
    ? _c(
        "span",
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-0",
              class: _vm.loadStatusBtnClass,
              attrs: { disabled: _vm.disableClick, small: "" },
              on: {
                click: function ($event) {
                  return _vm.openLoadTracking()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.shipmentDetail.archived
                      ? "Archived"
                      : _vm.shipmentDetail.hold
                      ? "Hold"
                      : _vm.loadStatusDisplay
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }