var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("(required)")]) : _vm._e(),
      ]),
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "7" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                transition: "scale-transition",
                "offset-y": "",
                "min-width": "auto",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mt-0 pt-0",
                              attrs: {
                                "append-icon": "mdi-calendar",
                                readonly: "",
                                disabled: _vm.disabled,
                                rules: _vm.dateValidationRules,
                              },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            "v-text-field",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.isDatePickerOpen,
                callback: function ($$v) {
                  _vm.isDatePickerOpen = $$v
                },
                expression: "isDatePickerOpen",
              },
            },
            [
              _c("v-date-picker", {
                on: { input: _vm.onDateInput },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "5" } },
        [
          _c("v-text-field", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##:##",
                expression: "'##:##'",
              },
            ],
            ref: "timeField",
            staticClass: "mt-0 pt-0",
            attrs: {
              disabled: _vm.disabled,
              rules: _vm.timeValidationRules,
              "append-icon": "mdi-clock-outline",
            },
            on: { input: _vm.onTimeInput },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }